import React, { useState, useMemo, useContext } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import omit from "lodash/omit";
import ScheduleFormType from "../../types/ScheduleFormType";
import { ScheduleContext, ScheduleDispatchContext } from "./ScheduleContext";

export default function AddScheduleForm({ tabKey }) {
  const state = useContext(ScheduleContext);
  const dispatch = useContext(ScheduleDispatchContext);
  const {
    scheduleModalForms: {
      [tabKey]: { cycleDateRange, scheduleText, cycleNumber },
    },
    scheduleModalForms,
    scheduleModalTabs,
  } = state;
  const otherCycleDateRangesInUse = Object.values(
    omit(scheduleModalForms, [tabKey])
  )
    .map((form) => form.cycleDateRange)
    .filter((range) => range.every((date) => date !== null));
  const [selectedCycleDates, setSelectedCycleDates] = useState(cycleDateRange);
  const [localScheduleText, setLocalScheduleText] = useState(scheduleText);
  const [localCycleNumber, setLocalCycleNumber] = useState(cycleNumber);
  const [showDatePickerError, setShowDatePickerError] = useState(false);

  const areSelectedDatesSame = useMemo(() => {
    const [cycleDateRangeStart, cycleDateRangeEnd] = cycleDateRange;
    const [selectedCycleDatesStart, selectedCycleDatesEnd] = selectedCycleDates;

    return (
      cycleDateRangeStart?.getTime?.() ===
        selectedCycleDatesStart?.getTime?.() &&
      cycleDateRangeEnd?.getTime?.() === selectedCycleDatesEnd?.getTime?.()
    );
  }, [cycleDateRange, selectedCycleDates]);

  const isScheduleTextSame = localScheduleText === scheduleText;
  const isCycleNumberSame = localCycleNumber === cycleNumber;

  const disableCreateEvents =
    !selectedCycleDates[0] ||
    !selectedCycleDates[1] ||
    !localScheduleText ||
    Number.isNaN(parseInt(localCycleNumber, 10)) ||
    (areSelectedDatesSame && isScheduleTextSame && isCycleNumberSame);

  const handleCreateEvents = () => {
    dispatch({
      type: "UPDATE_SCHEDULE_MODAL_FORMS",
      tabKey,
      cycleDateRange: selectedCycleDates,
      scheduleText: localScheduleText,
      cycleNumber: localCycleNumber,
    });

    if (parseInt(localCycleNumber, 10) !== cycleNumber) {
      const otherTabs = scheduleModalTabs.filter(
        (tab) => tab.eventKey !== tabKey
      );
      const newTab = {
        eventKey: tabKey,
        title: `Cycle ${localCycleNumber}`,
      };

      dispatch({
        type: "UPDATE_SCHEDULE_MODAL_TABS",
        payload: [...otherTabs, newTab],
      });
    }
  };

  function disableDateCheck(date) {
    return otherCycleDateRangesInUse.some((dateRange) => {
      const [start, end] = dateRange;
      return (
        start.getTime() <= date.getTime() && date.getTime() <= end.getTime()
      );
    });
  }

  function handleSelectedCycleDates(dateRange) {
    const [selectedStart, selectedEnd] = dateRange;
    const selectedDateRangeOverlaps = otherCycleDateRangesInUse.some(
      (dateRangeInUse) => {
        const [start, end] = dateRangeInUse;
        return (
          (selectedStart.getTime() <= start.getTime() &&
            start.getTime() <= selectedEnd.getTime()) ||
          (selectedStart.getTime() <= end.getTime() &&
            end.getTime() <= selectedEnd.getTime())
        );
      }
    );

    if (selectedDateRangeOverlaps) {
      setShowDatePickerError(true);
      return;
    }

    setShowDatePickerError(false);
    setSelectedCycleDates(dateRange);
  }

  function handleUndoChanges() {
    setSelectedCycleDates(cycleDateRange);
    setLocalScheduleText(scheduleText);
    setLocalCycleNumber(cycleNumber);
  }

  return (
    <Form>
      <Row>
        <Col>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="scheduleCycleNumberInput"
          >
            <Col className="pe-0">
              <Form.Label>1. Enter the number of this cycle.</Form.Label>
            </Col>
            <Col xs={3}>
              <Form.Control
                size="sm"
                type="number"
                value={localCycleNumber}
                onChange={(e) => setLocalCycleNumber(e.target.value)}
                min={0}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="scheduleTextArea">
            <Form.Label>
              2. Enter the schedule for each day of this cycle.
            </Form.Label>
            <Col>
              <Form.Control
                size="sm"
                as="textarea"
                rows={6}
                placeholder={
                  "Tylenol 500 mg daily\n\nDexamethasone 40 mg with breakfast"
                }
                value={localScheduleText}
                onChange={(e) => setLocalScheduleText(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="scheduleCycleDatePicker"
          >
            <Form.Label>3. Select the date range of this cycle.</Form.Label>
            <DateRangePicker
              onChange={(value) => handleSelectedCycleDates(value)}
              tileDisabled={({ date }) => disableDateCheck(date)}
              value={selectedCycleDates}
              clearIcon={null}
              calendarType="US"
            />
            <Form.Control.Feedback
              type="invalid"
              className={showDatePickerError ? "d-block" : ""}
            >
              The selected date range overlaps with other cycles.
            </Form.Control.Feedback>
            <Container className="mt-2">
              <Row>
                <Col className="d-flex justify-content-end">
                  <Button
                    variant="secondary"
                    size="sm"
                    className="mx-3"
                    disabled={
                      areSelectedDatesSame &&
                      isScheduleTextSame &&
                      isCycleNumberSame
                    }
                    onClick={() => handleUndoChanges()}
                  >
                    Undo Changes
                  </Button>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    disabled={disableCreateEvents}
                    onClick={handleCreateEvents}
                  >
                    Create Events
                  </Button>
                </Col>
              </Row>
            </Container>
            <Form.Text id="scheduleCycleDatePickerHelp" muted>
              Note: Reapplying &quot;Create Events&quot; will remove all
              existing events for this cycle and create new ones with the
              options above.
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
}

AddScheduleForm.propTypes = ScheduleFormType;

AddScheduleForm.defaultProps = {
  cycleDateRange: [],
  scheduleText: "",
  cycleNumber: 1,
};
