import React, { useState } from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "react-textarea-autosize";
import "../../stylesheets/Schedule/EventContent.css";

export default function EventContent({ scheduleText, date }) {
  const [eventText, setEventText] = useState(scheduleText);

  return (
    <TextareaAutosize
      id={`event-text-area-${date}`}
      value={eventText}
      onChange={(e) => setEventText(e.target.value)}
      className="event-text-area"
    />
  );
}

EventContent.propTypes = {
  scheduleText: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};
