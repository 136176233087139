import React, { createContext } from "react";
import scheduleStateType from "../../types/ScheduleStateType";

export const ScheduleContext = createContext(null);
export const ScheduleDispatchContext = createContext(null);

export function ScheduleProvider({ state, dispatch, children }) {
  return (
    <ScheduleContext.Provider value={state}>
      <ScheduleDispatchContext.Provider value={dispatch}>
        {children}
      </ScheduleDispatchContext.Provider>
    </ScheduleContext.Provider>
  );
}

ScheduleProvider.propTypes = scheduleStateType;
