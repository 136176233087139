import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DynamicTabsType from "../../types/DynamicTabsType";

export default function DynamicTabs({
  tabs,
  activeTab,
  addTabLabel,
  handleSelectTab,
  renderContent,
  disableAddTab,
}) {
  return (
    <Tabs
      activeKey={activeTab}
      onSelect={(key) => handleSelectTab(key)}
      className="mb-3"
    >
      {tabs.map(({ eventKey, title }) => (
        <Tab
          eventKey={eventKey}
          title={title}
          key={eventKey}
          disabled={activeTab === eventKey}
        >
          {renderContent(eventKey)}
        </Tab>
      ))}
      <Tab eventKey="addTab" title={addTabLabel} disabled={disableAddTab} />
    </Tabs>
  );
}

DynamicTabs.propTypes = DynamicTabsType;

DynamicTabs.defaultProps = {
  addTabLabel: "Add Tab",
  tabs: [{ eventKey: "tab1", title: "Tab 1" }],
  activeTab: "tab1",
  disableAddTab: false,
};
