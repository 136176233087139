import PropTypes from "prop-types";

export default {
  state: PropTypes.shape({
    scheduleModaltabs: PropTypes.arrayOf(
      PropTypes.shape({
        eventKey: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
    activeScheduleModalTab: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
