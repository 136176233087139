import React from "react";
import PropTypes from "prop-types";
import "../../stylesheets/Schedule/DayCellContent.css";

export default function DayCellContent({ date, dayNumberText }) {
  const formattedDate = date.toISOString().split("T")[0];

  return (
    <>
      <span className={`day-cell-content-cycle-text-${formattedDate}`} />
      <span>{dayNumberText}</span>
    </>
  );
}

DayCellContent.propTypes = {
  dayNumberText: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
};
