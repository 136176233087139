import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Schedule from "./components/Schedule";

function App() {
  return (
    <div className="App">
      <Navbar bg="light" className="mb-3">
        <Row className="m-auto">
          <Col>
            <Navbar.Brand className="px-0 d-block font-weight-bold">
              MedSchedule
            </Navbar.Brand>
          </Col>
        </Row>
      </Navbar>
      <Schedule />
    </div>
  );
}

export default App;
