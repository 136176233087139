import React, {
  useReducer,
  useMemo,
  useCallback,
  useState,
  useRef,
} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import AddScheduleSideModal from "./AddScheduleSideModal";
import scheduleReducer from "../../reducers/scheduleReducer";
import EventContent from "./EventContent";
import DayCellContent from "./DayCellContent";
import { ScheduleProvider } from "./ScheduleContext";

const initialState = {
  scheduleModalForms: {
    tab1: {
      cycleDateRange: [null, null],
      scheduleText: "",
      cycleNumber: 1,
    },
  },
  scheduleModalTabs: [{ eventKey: "tab1", title: "Cycle 1" }],
  activeScheduleModalTab: "tab1",
};

export default function Schedule() {
  const [state, dispatch] = useReducer(scheduleReducer, initialState);
  const [show, setShow] = useState(false);
  const { scheduleModalForms } = state;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const events = useMemo(() => {
    const evts = [];

    Object.values(scheduleModalForms).forEach(
      ({
        cycleDateRange: [cycleDateRangeStart, cycleDateRangeEnd],
        scheduleText,
        cycleNumber,
      }) => {
        if (
          !cycleDateRangeStart ||
          !cycleDateRangeEnd ||
          !scheduleText ||
          Number.isNaN(cycleNumber)
        )
          return;

        for (
          let d = new Date(cycleDateRangeStart), i = 1;
          d <= cycleDateRangeEnd;
          d.setDate(d.getDate() + 1), i += 1
        ) {
          const event = {
            start: new Date(d),
            end: new Date(d),
            allDay: true,
            cycleDay: i,
            cycleNumber,
            scheduleText,
          };

          evts.push(event);
        }
      }
    );

    return evts;
  }, [scheduleModalForms]);

  const calendar1Ref = useRef(null);
  const calendar2Ref = useRef(null);

  const renderMemoizedDayCellContent = useCallback(
    ({ date, dayNumberText }) => (
      <DayCellContent date={date} dayNumberText={dayNumberText} />
    ),
    []
  );

  function setDayCellContentCycleText(args, text) {
    const {
      event: {
        startStr: eventDate,
        extendedProps: { cycleDay, cycleNumber: cycleNum },
      },
    } = args;
    const dayCellContentCycleTextEls = document.getElementsByClassName(
      `day-cell-content-cycle-text-${eventDate}`
    );

    if (dayCellContentCycleTextEls.length > 0) {
      for (let i = 0; i < dayCellContentCycleTextEls.length; i += 1) {
        const dayCellContentCycleTextEl = dayCellContentCycleTextEls[i];
        dayCellContentCycleTextEl.textContent =
          text === undefined ? `D${cycleDay}C${cycleNum}` : text;
      }
    }
  }

  function renderEventContent(eventInfo) {
    const {
      event: {
        extendedProps: { scheduleText },
        startStr,
      },
    } = eventInfo;
    return <EventContent scheduleText={scheduleText} date={startStr} />;
  }

  function getFirstOfNextMonth() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    if (currentMonth === 11) {
      return new Date(currentYear, 0, 1);
    }

    return new Date(currentYear, currentMonth + 1, 1);
  }

  function goToPrevMonth() {
    const calendar1Api = calendar1Ref.current.getApi();
    const calendar2Api = calendar2Ref.current.getApi();

    calendar1Api.prev();
    calendar2Api.prev();
  }

  function goToNextMonth() {
    const calendar1Api = calendar1Ref.current.getApi();
    const calendar2Api = calendar2Ref.current.getApi();

    calendar1Api.next();
    calendar2Api.next();
  }

  function goToToday() {
    const calendar1Api = calendar1Ref.current.getApi();
    const calendar2Api = calendar2Ref.current.getApi();

    calendar1Api.today();
    calendar2Api.gotoDate(getFirstOfNextMonth());
  }

  const headerToolbarOptions = {
    start: "AddScheduleSideModalBtn",
    center: "title",
    end: "PrintBtn TodayBtn PrevBtn,NextBtn",
  };

  const customButtonsOptions = {
    AddScheduleSideModalBtn: {
      text: "Add Schedule",
      click: () => handleShow(),
    },
    PrintBtn: {
      text: "Print",
      click: () => window.print(),
    },
    PrevBtn: {
      text: "<",
      hint: "Previous month",
      click: () => goToPrevMonth(),
    },
    NextBtn: {
      text: ">",
      hint: "Next month",
      click: () => goToNextMonth(),
    },
    TodayBtn: {
      text: "Today",
      hint: "Today",
      click: () => goToToday(),
    },
  };

  const buttonIconsOptions = {
    PrintBtn: "bi bi-printer-fill",
    PrevBtn: "bi bi-chevron-left",
    NextBtn: "bi bi-chevron-right",
  };

  return (
    <ScheduleProvider state={state} dispatch={dispatch}>
      <Row className="mx-0">
        <Col className="content-col calendar-1">
          <AddScheduleSideModal show={show} handleClose={handleClose} />
          <FullCalendar
            plugins={[dayGridPlugin, bootstrap5Plugin]}
            initialView="dayGridMonth"
            events={events}
            eventContent={(e) => renderEventContent(e)}
            eventDidMount={(args) => setDayCellContentCycleText(args)}
            eventWillUnmount={(args) => setDayCellContentCycleText(args, null)}
            dayCellContent={(args) => renderMemoizedDayCellContent(args)}
            handleWindowResize={false}
            height="auto"
            showNonCurrentDates={false}
            fixedWeekCount={false}
            headerToolbar={headerToolbarOptions}
            customButtons={customButtonsOptions}
            themeSystem="bootstrap5"
            buttonIcons={buttonIconsOptions}
            ref={calendar1Ref}
            stickyHeaderDates={false}
          />
        </Col>
      </Row>
      <Row className="mt-3 mx-0">
        <Col className="content-col calendar-2">
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={events}
            eventContent={(e) => renderEventContent(e)}
            eventDidMount={(args) => setDayCellContentCycleText(args)}
            eventWillUnmount={(args) => setDayCellContentCycleText(args, null)}
            dayCellContent={(args) => renderMemoizedDayCellContent(args)}
            handleWindowResize={false}
            height="auto"
            showNonCurrentDates={false}
            fixedWeekCount={false}
            headerToolbar={{ start: "", center: "title", end: "" }}
            initialDate={getFirstOfNextMonth()}
            ref={calendar2Ref}
            stickyHeaderDates={false}
          />
        </Col>
      </Row>
    </ScheduleProvider>
  );
}
