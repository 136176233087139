import React, { useContext, useCallback } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import PropTypes from "prop-types";
import AddScheduleForm from "./AddScheduleForm";
import DynamicTabs from "../util/DynamicTabs";
import { ScheduleContext, ScheduleDispatchContext } from "./ScheduleContext";

export default function AddScheduleSideModal({ show, handleClose }) {
  const state = useContext(ScheduleContext);
  const dispatch = useContext(ScheduleDispatchContext);

  const { scheduleModalTabs, activeScheduleModalTab, scheduleModalForms } =
    state;
  const latestTab = scheduleModalTabs[scheduleModalTabs.length - 1].eventKey;
  const {
    cycleDateRange: [cycleDateRangeStart, cycleDateRangeEnd],
    scheduleText,
    cycleNumber,
  } = scheduleModalForms[latestTab];
  const isAddTabDisabled =
    !cycleDateRangeStart ||
    !cycleDateRangeEnd ||
    !scheduleText ||
    Number.isNaN(cycleNumber);

  const tabTitlePrefix = "Cycle";

  const handleSelectTab = useCallback(
    (key) => {
      if (key === "addTab") {
        const newTab = {
          eventKey: `tab${scheduleModalTabs.length + 1}`,
          title: `${tabTitlePrefix} 1`,
        };
        dispatch({
          type: "UPDATE_SCHEDULE_MODAL_TABS",
          payload: [...scheduleModalTabs, newTab],
        });
        dispatch({
          type: "UPDATE_SCHEDULE_MODAL_ACTIVE_TAB",
          payload: newTab.eventKey,
        });
        dispatch({
          type: "UPDATE_SCHEDULE_MODAL_FORMS",
          tabKey: newTab.eventKey,
          cycleDateRange: [null, null],
          scheduleText: "",
          cycleNumber: 1,
        });
        return;
      }

      dispatch({ type: "UPDATE_SCHEDULE_MODAL_ACTIVE_TAB", payload: key });
    },
    [scheduleModalTabs]
  );

  return (
    <Offcanvas show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Add Schedule</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <DynamicTabs
          tabs={scheduleModalTabs}
          activeTab={activeScheduleModalTab}
          addTabLabel="+"
          handleSelectTab={handleSelectTab}
          disableAddTab={isAddTabDisabled}
          renderContent={(tabKey) => <AddScheduleForm tabKey={tabKey} />}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
}

AddScheduleSideModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
