import PropTypes from "prop-types";

export default {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      eventKey: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  activeTab: PropTypes.string,
  addTabLabel: PropTypes.string,
  handleSelectTab: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired,
  disableAddTab: PropTypes.bool,
};
