export default function scheduleReducer(state, action) {
  switch (action.type) {
    case "UPDATE_SCHEDULE_MODAL_FORMS": {
      const { cycleDateRange, scheduleText, cycleNumber, tabKey } = action;
      const newScheduleModalForms = {
        ...state.scheduleModalForms,
        [tabKey]: { cycleDateRange, scheduleText, cycleNumber },
      };

      return { ...state, scheduleModalForms: newScheduleModalForms };
    }
    case "UPDATE_SCHEDULE_MODAL_ACTIVE_TAB": {
      const newActiveTab = { activeScheduleModalTab: action.payload };

      return { ...state, ...newActiveTab };
    }
    case "UPDATE_SCHEDULE_MODAL_TABS": {
      const newTabs = { scheduleModalTabs: action.payload };

      return { ...state, ...newTabs };
    }
    default: {
      return state;
    }
  }
}
